<!--微信重定向中转页面-->
<template>
  <div class="transit"></div>
</template>

<script>
  export default {
    name: "transit",
    mounted() {
      console.log(
        window.location.href,
        "window.location.search",
        window.location.search,
        "---",
        window.location.hash.split("?")[1]
      );
      let hashParams = window.location.hash.split("?")[1];
      let searchParams = window.location.search;
      let paramString = searchParams;
      if (!paramString) {
        paramString = "?" + hashParams;
      }
      window.location.href = window.location.origin + "/#/" + paramString;
    },
  };
</script>

<style scoped>
</style>
